import React from "react";
import { Link } from "react-router-dom";
import SmallScreenCards from "./cards/SmallScreenCards";

const LandingHead = () => {
  return (
    <>
      <div className="relative">
        <img
          className="w-full h-auto object-cover lg:hidden"
          src="/Images/HeroImageMobile.png"
          alt="Background"
        />
        <img
          className="w-full h-full object-contain hidden lg:block"
          src="/Images/HeroImage1.png" 
          alt="Background"
        />

        <div className="absolute inset-0 md:-top-32 -top-[680px]  flex flex-col items-center justify-center text-center">
          <h1 className="text-white text-2xl md:text-4xl lg:text-5xl font-bold">
            Connecting Life by Anchoring Trust
          </h1>
          <div className="flex gap-4 mt-6">
          <Link to="/getintouch">
            <div className="flex">
              <button className="bg-[#FF8730] text-white md:px-6 px-3 md:py-3 py-1 rounded-md hover:bg-orange-600 flex items-center gap-1">
                Get in touch
                <img
                  src="/assets/letsTalk.png"
                  alt="Lets Talk"
                  className="w-4 h-4"
                />
              </button>
            </div>
            </Link>
            <Link to="/company">
            <button
              className="bg-white text-[#296D9C] md:px-6 px-3 md:py-3 py-1 rounded-md hover:bg-gray-200"
            >
              Know More
            </button>
            </Link>
          </div>
        </div>

       
        <div className="absolute bottom-0 w-full px-4 lg:px-0 transform translate-y-1/2 hidden md:block">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mx-3">
            <div className="bg-white p-8 shadow-md rounded-xl text-md text-gray-600 md:text-justify text-center flex items-center">
              <img
                className="mx-4"
                src="/assets/Cuttingedge.png"
                alt=""
                style={{ width: "45px", height: "45px" }}
              />
              <div>
                <h3 className="font-bold text-black">Our Quality</h3>
                <p className="text-sm text-justify">
                  ISO 9001:2015 – Global quality management for consistent
                  practices and delivery
                </p>
              </div>
            </div>
            <div className="bg-white p-8 shadow-md rounded-xl text-md text-gray-600 md:text-justify text-center flex items-center">
              <img
                className="mx-4"
                src="/assets/Safety.png"
                alt=""
                style={{ width: "45px", height: "45px" }}
              />
              <div>
                <h3 className="font-bold text-black">Safety Commitment</h3>
                <p className="text-sm text-justify">
                  Unwavering commitment to safety by implementing stringent
                  measures to minimize accident risks
                </p>
              </div>
            </div>
            <div className="bg-white p-8 shadow-md rounded-xl text-md text-gray-600 md:text-justify text-center flex items-center ">
              <img
                className="mx-4"
                src="/assets/Industry.png"
                alt=""
                style={{ width: "45px", height: "45px" }}
              />
              <div>
                <h3 className="font-bold text-black">Leadership</h3>
                <p className="text-sm text-justify">
                  Redefining excellence in the maritime industry through
                  innovation and reliability
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* New component for smaller screens */}
        <div className="md:hidden">
          <SmallScreenCards />
        </div>
      </div>
      <div className="md:mb-52 lg:mb-20"></div>
    </>
  );
};

export default LandingHead;
