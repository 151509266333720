
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Sticky = () => {
  const location = useLocation();
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);
  const [showBusinessDropdown, setShowBusinessDropdown] = useState(false);
  const aboutDropdownRef = useRef(null);
  const businessDropdownRef = useRef(null);

  
  const toggleAboutDropdown = () => {
    setShowAboutDropdown((prev) => !prev);
    setShowBusinessDropdown(false); // Close Business dropdown when About is toggled
  };

  
  const toggleBusinessDropdown = () => {
    setShowBusinessDropdown((prev) => !prev);
    setShowAboutDropdown(false); // Close About dropdown when Business is toggled
  };

  
  const handleClickOutside = (event) => {
  
    if (
      aboutDropdownRef.current &&
      !aboutDropdownRef.current.contains(event.target)
    ) {
      setShowAboutDropdown(false);
    }
  
    if (
      businessDropdownRef.current &&
      !businessDropdownRef.current.contains(event.target)
    ) {
      setShowBusinessDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="sticky z-[1050] top-0 left-0 right-0 md:flex items-center h-16 justify-between hidden bg-white px-2">
      <div className="text-neutral-950  text-center flex justify-between w-96 h-full">
        <Link to="/">
          <div className="flex items-center justify-start h-full ">
            <img
              src="/assets/Logo.png"
              className="px-1"
              style={{ width: "70px", height: "auto", filter: "brightness(1)" }}
              alt="Logo"
            />
            <h3 className="content-center font-semibold w-full text-sm">
              SEVEN ISLANDS SHIPPING LIMITED
            </h3>
          </div>
        </Link>
      </div>

      <div className="flex items-start justify-end font-semibold w-full text-sm">
        <div
          className="cursor-pointer flex text-black z-50 p-3 pl-2 px-4 text-center relative"
          onClick={toggleAboutDropdown}
          ref={aboutDropdownRef}
        >
          <p>About</p>
          <img
            src="/assets/Dropdown.png"
            className={`h-4 ml-1 ${showAboutDropdown ? "rotate-180" : ""}`}
            alt="Dropdown"
          />
          {showAboutDropdown && (
            <div className="absolute z-50 top-16 left-0 bg-white w-36 shadow-md rounded-md p-2">
              <div className="py-2 text-black border-b">
                <Link to="/company" className="block">
                The Company
                </Link>
              </div>
              <div className="py-2 text-black border-b">
                <Link to="/leadership" className="block">
                  Leadership
                </Link>
              </div>
              <div className="py-2 text-black ">
                <Link to="/compliance" className="block">
                  Governance and Compliance
                </Link>
              </div>
            </div>
          )}
        </div>
        <div
          className="cursor-pointer flex text-black z-50 p-3 pl-2 px-4 text-center relative"
          onClick={toggleBusinessDropdown}
          ref={businessDropdownRef}
        >
          <p>Business Area</p>
          <img
            src="/assets/Dropdown.png"
            className={`h-4 ml-1 ${showBusinessDropdown ? "rotate-180" : ""}`}
            alt="Dropdown"
          />
          {showBusinessDropdown && (
            <div className="absolute z-50 top-16 left-0 bg-white w-36 shadow-md rounded-md p-2">
              <div className="py-2 text-black border-b">
                <Link to="/company/vesselprofile" className="block">
                  Vessel Profile
                </Link>
              </div>
              <div className="py-2 text-black">
                <Link to="/clients" className="block">
                  Clients
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* Other Links */}
        <div className={`cursor-pointer z-50 p-3 ${location.pathname === "/company/gallery" ? "underline" : ""}`}>
          <Link to="/company/gallery">Gallery</Link>
        </div>

        <div className={`cursor-pointer z-50 p-3 ${location.pathname === "/career" ? "underline" : ""}`}>
          <Link to="/career">Careers</Link>
        </div>

        <div className={`cursor-pointer z-50 p-3 ${location.pathname === "/simti" ? "underline" : ""}`}>
          <Link to="/simti">Maritime Institute</Link>
        </div>

        {/* Connect Button */}
        <div className="p-3">
          <Link to="/getintouch" className="flex items-center gap-2">
            <div className="bg-[#296D9C] z-50 text-white p-1 rounded-md hover:bg-[#4087ba] w-28 justify-center flex items-center gap-2 cursor-pointer">
              Connect <img src="/Images/letstalk.png" alt="Connect" className="w-4" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sticky;
