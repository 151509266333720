import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@react-hook/media-query";

const SimtTitle = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");

  const images = [
    "/Images/Simt/Academic-building.jpeg",
    "/Images/Simt/Academic-building-2.jpeg",
    "/Images/Simt/Academic-3.jpeg",
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <>
      <div className="h-[690px] md:h-full flex flex-col justify-between items-center relative pb-10 bg-[#296D9C]">
        <div className="text-white rounded-lg flex md:flex-row flex-col justify-between md:items-start mt-2 md:mt-36 w-full px-4 lg:px-20">
          <h1 className="text-3xl lg:text-4xl font-bold text-center md:text-start md:no-underline underline">
            <span className="font-medium">About</span> Seven Islands Maritime Training 
            Institute
          </h1>
          {!isMobile && (
            <p className="md:block text-lg md:text-lg lg:text-xl text-justify w-[50%]">
              Welcome to the Seven Islands Maritime Training Institute (SIMTI),
              a distinguished institution committed to shaping the future
              leaders of the maritime industry. As a proud initiative of Seven
              Islands Shipping Limited (SISL), SIMTI stands as a beacon of
              excellence in maritime education and training.
            </p>
          )}
          <p className="md:hidden text-justify text-xs py-3">
            Welcome to the Seven Islands Maritime Training Institute (SIMTI), a
            distinguished institution committed to shaping the future leaders of
            the maritime industry. As a proud initiative of Seven Islands
            Shipping Limited (SISL), SIMTI stands as a beacon of excellence in
            maritime education and training.
          </p>
        </div>
        <div className="relative w-full h-[700px] mt-5">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt=""
              className={`absolute top-0 left-0 w-full h-full object-contain transition-opacity duration-1000  ${
                currentImageIndex === index ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}
        </div>
        <p className="px-7 text-justify text-white font-normal md:text-lg mt-5 text-xs">
          SIMTI has been set up under Seven Islands Maritime Training
          Foundation (SIMTF), a Section 8 company and wholly owned subsidiary of
          SISL. Our institute is dedicated to fostering a generation of highly
          skilled maritime professionals equipped to meet the evolving demands
          of the global maritime sector. At SIMTI, we prioritize excellence,
          innovation, and integrity in all facets of our educational endeavors.
        </p>
      </div>
    </>
  );
};

export default SimtTitle;
