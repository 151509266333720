import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import { Link } from "react-router-dom";

const StickyMobile = () => {
  const location = useLocation();

  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [showAboutAccordion, setShowAboutAccordion] = useState(false);
  const [showBusinessAccordion, setShowBusinessAccordion] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const toggleHamburgerMenu = () => {
    setShowAboutAccordion(false);
    setShowBusinessAccordion(false);
    setShowHamburgerMenu(!showHamburgerMenu);
  };

  const toggleAboutAccordion = () => {
    setShowAboutAccordion(!showAboutAccordion);
  };

  const toggleBusinessAccordion = () => {
    setShowBusinessAccordion(!showBusinessAccordion);
  };

  return (
    <div className={`sticky z-[1050] bg-white top-0 left-0 right-0 flex items-center h-14 justify-between px-2 md:hidden `}>
      <Link to="/">
        <div className="pt-4 cursor-pointer z-50 text-neutral-950 h-14 w-[250px] text-center flex justify-between items-start">
          <div className="flex items-center">
            <img src="/assets/Logo.png" className="px-2" alt="" />
            <h3>SISL Shipping Ltd. </h3>
          </div>
        </div>
      </Link>
      <div className="flex font-semibold">
        <button className="text-neutral-950 h-10 rounded-bl-xl" onClick={toggleHamburgerMenu}>
          {showHamburgerMenu ? (
            <img src="/assets/close_icon.png" className="w-6 mx-2" alt="Close Menu" />
          ) : (
            <img src="/assets/ham_blue.png" className="w-6 mx-2" alt="" />
          )}
        </button>

        {showHamburgerMenu && isSmallScreen && (
          <div className="fixed top-0 right-0 h-full w-full bg-white z-[1100] shadow">
            <div className="flex justify-between w-full py-6 px-3 items-center" onClick={toggleHamburgerMenu}>
              <img src="/assets/Logo.png" className="px-2" alt="" style={{ width: "70px", height: "auto", filter: "brightness(1)" }} />
              <img className="" src="/assets/Close.png" alt="" />
            </div>

            <div className="flex flex-col h-60">
              {/* About Us Dropdown */}
              <div>
                <div className="flex items-center font-medium text-lg my-2 ml-4" onClick={toggleAboutAccordion}>
                  About
                  {showAboutAccordion ? (
                    <img src="/assets/navDropdown.png" className="ml-2 w-4 rotate-180" alt="Collapse" />
                  ) : (
                    <img src="/assets/navDropdown.png" className="ml-2 w-4" alt="Collapse" />
                  )}
                </div>
                {showAboutAccordion && (
                  <div>
                    <div className="flex gap-2 font-medium text-lg my-2 ml-8">
                      <Link to="/company" onClick={toggleHamburgerMenu}>The Company</Link>
                    </div>
                    <div className="flex gap-2 font-medium text-lg my-2 ml-8">
                      <Link to="/leadership" onClick={toggleHamburgerMenu}>Leadership</Link>
                    </div>
                    <div className="flex gap-2 font-medium text-lg my-2 ml-8">
                      <Link to="/compliance" onClick={toggleHamburgerMenu}>Governance and Compliance</Link>
                    </div>
                  </div>
                )}
              </div>

              {/* Business Area Dropdown */}
              <div>
                <div className="flex items-center font-medium text-lg my-2 ml-4" onClick={toggleBusinessAccordion}>
                  Business Area
                  {showBusinessAccordion ? (
                    <img src="/assets/navDropdown.png" className="ml-2 w-4 rotate-180" alt="Collapse" />
                  ) : (
                    <img src="/assets/navDropdown.png" className="ml-2 w-4" alt="Collapse" />
                  )}
                </div>
                {showBusinessAccordion && (
                  <div>
                    <div className="flex gap-2 font-medium text-lg my-2 ml-8">
                      <Link to="/company/vesselprofile" onClick={toggleHamburgerMenu}>Vessel Profile</Link>
                    </div>
                    <div className="flex gap-2 font-medium text-lg my-2 ml-8">
                      <Link to="/clients" onClick={toggleHamburgerMenu}>Clients</Link>
                    </div>
                  </div>
                )}
              </div>

              {/* Other Links */}
              <div className="font-medium text-lg my-2 ml-4">
                <Link to="/company/gallery" onClick={toggleHamburgerMenu}>Gallery</Link>
              </div>
              <div className="font-medium text-lg my-2 ml-4">
                <Link to="/career" onClick={toggleHamburgerMenu}>Careers</Link>
              </div>
              <div className="font-medium text-lg my-2 ml-4">
                <Link to="/simti" onClick={toggleHamburgerMenu}>Institute</Link>
              </div>
            </div>

            {/* Connect with Us Button */}
            <Link to="/getintouch" onClick={toggleHamburgerMenu}>
              <div className="bg-[#296D9C] mx-4 mt-36 z-[1000] text-white p-1 rounded-md hover:bg-[#4087ba] justify-center flex items-center gap-2 cursor-pointer">
                Connect with us
                <img src="/Images/letstalk.png" alt="" className="w-4" />
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default StickyMobile;
