import React from "react";
import { useMediaQuery } from "@react-hook/media-query";

const videoSource = '/Vids/banner.mp4';

const CompanyTitle = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <>
      <div className="h-full flex flex-col justify-between items-center relative pb-10 bg-[#296D9C]">
        <div className="text-white rounded-lg flex md:flex-row flex-col justify-between md:items-start mt-2 md:mt-36 w-full px-4 lg:px-20">
          <h1 className="text-3xl lg:text-4xl font-bold text-center md:text-start md:no-underline underline">
            <span className="font-medium">About</span> Seven Islands Shipping Limited
          </h1>
          {!isMobile && (
            <p className="md:block text-lg md:text-lg lg:text-xl text-justify w-[50%]">
              Established with a vision to redefine shipping, we pride ourselves
              on a legacy built upon precision, reliability and innovation. We
              are more than a shipping company; we are facilitators of global
              energy trade.
            </p>
          )}
          <p className="md:hidden text-justify text-xs py-3">
            Established with a vision to redifine shipping, we pride ourselves
            on a legacy build upon precision, reliabiltiy, and innovation.we are
            more than a shipping company;we are facilitators of global energy
            trade
          </p>
        </div>
        <div className="">
          <video
            src={videoSource}
            // controls
            autoPlay
            loop
            muted
            onContextMenu={(e) => e.preventDefault()}
            className="rounded-3xl  px-3 py-4 mt-5 w-auto h-auto"
          />
        </div>
        <p className="px-7 text-justify text-white font-normal md:text-lg mt-5 text-xs">
          Seven Islands Shipping Limited (SISL) is a Mumbai-based liquid and gas
          seaborne logistics company that has been operating ocean going tankers
          for the past 20 years after being incorporated under the Companies
          Act, 1956, in Mumbai (India) on 2nd May, 2002. The Company is
          compliant with the ISO 9001:2015 standard, is certified by the
          American Bureau of Shipping and is in conformance with the quality
          standards set by them. SISL is registered under the Directorate
          General of Shipping, Government of India and its fleet is classed with
          the Indian Register of Shipping. The Company is committed to safe
          transportation, efficient services and cleaner seas. They recognize
          their responsibility for the safety of life at sea and ensuring a
          cleaner environment, hence developing and maintaining high standards
          of their fleet.
          <br /> 
          <br/>
          The Company has been profitable every year since inception and
          the quantum of its earnings has increased steadily and rapidly. SISL
          currently owns a fleet of 25 vessels - 2 crude oil tankers, 2 gas
          carriers and 21 liquid product carriers. The crude oil vessels and gas
          carriers cater to the nation’s growing demand for crude oil and LPG
          imports respectively, while the product tankers transport various
          refined oil products to the ports of consumption in India and abroad.
          SISL undertakes regular sale and purchase of vessels with the
          objective of fleet upgrade and renewal, to cater to its clients’
          needs. It has a healthy mix of vessels in the time and spot charter
          markets.
        </p>
      </div>
    </>
  );
};

export default CompanyTitle;