import React, { useState } from "react";
import "../../index.css";

const OurPrograms = () => {
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);

  const toggleExpanded1 = () => {
    setExpanded1(!expanded1);
  };

  const toggleExpanded2 = () => {
    setExpanded2(!expanded2);
  };

  const toggleExpanded3 = () => {
    setExpanded3(!expanded3);
  };
  return (
    <>
      <div className="flex flex-col lg:flex-row w-full md:h-[500px] relative md:mb-12 items-center bg-[#F2FAFF] mb-[750px]">
        <div className="  px-4 md:px-11 lg:w-[200%]  md:pt-0">
          <div className="flex flex-col gap-4 md:py-12 items-start">
            {/* <h3 className="text-gray-500 text-sm md:text-base text-justify mb-6">
              We strive to gain a deep understanding of customer needs and work
              to provide our clients with a comprehensive logistics solution
              along with timely loading and delivery of cargo and regular
              updates on shipments. We are guided by the following values
              enshrined in our corporate culture
            </h3> */}
            <div className="scroll">
              <div className="flex mb-4 flex-row  items-center">
                <div>
                  <h1 className="font-bold">Our Programs</h1>
                  <p className="text-gray-400 text-justify md:pr-5">
                    {expanded1 ? (
                      <>
                        SIMTI offers a comprehensive array of programs tailored
                        to meet the diverse needs of the maritime industry. Our
                        curriculum is meticulously crafted by experienced
                        maritime educators and industry experts, ensuring that
                        our students receive cutting-edge training in Nautical
                        Science and other specialized maritime disciplines. With
                        a blend of theoretical knowledge and hands-on
                        experience, our programs empower students to excel in
                        their chosen careers upon graduation.{" "}
                        <button
                          className="text-[#296D9C] font-bold"
                          onClick={toggleExpanded1}
                        >
                          read less
                        </button>
                      </>
                    ) : (
                      <>
                        SIMTI offers a comprehensive array of programs tailored
                        to meet the diverse needs of the maritime industry. Our
                        curriculum is meticulously crafted by experienced
                        maritime educators and industry experts, ensuring..
                        <button
                          className="text-[#296D9C] font-bold"
                          onClick={toggleExpanded1}
                        >
                          read more
                        </button>
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex mb-4 flex-row  items-center">
                <div>
                  <h1 className="font-bold">Infrastructure and Facilities</h1>
                  <p className="text-gray-400 text-justify md:pr-5">
                    {expanded1 ? (
                      <>
                        Built on 18.4 acres of contiguous land,
                        SIMTI boasts a modern campus featuring state-of-the-art
                        facilities and amenities. Our premises include a
                        workshop spanning approximately 50,000
                        square feet of developed area apart from cutting-edge learning tools and infrastructure
                        to ensure well-rounded development of all candidates.The campus is strategically located
                        along State Highway 92, in close proximity to Mumbai.
                        The institutional infrastructure has been developed with a 
                        focus on adhering to the stringent
                        guidelines and standards outlined by the Directorate
                        General of Shipping, Government of India. Our eventual
                        goal is to establish a 'Center of Maritime Excellence' –
                        the first of its kind in India.{" "}
                        <button
                          className="text-[#296D9C] font-bold"
                          onClick={toggleExpanded1}
                        >
                          read less
                        </button>
                      </>
                    ) : (
                      <>
                         Built on 18.4 acres of contiguous land,
                        SIMTI boasts a modern campus featuring state-of-the-art
                        facilities and amenities.Our..
                        <button
                          className="text-[#296D9C] font-bold"
                          onClick={toggleExpanded1}
                        >
                          read more
                        </button>
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex mb-4 flex-row items-center">
                <div>
                  <h1 className="font-bold">Faculty and Staff</h1>
                  <p className="text-gray-400 text-justify md:pr-5">
                    {expanded2 ? (
                      <>
                        SIMTI is proud to attract some of the brightest minds in
                        the maritime industry to serve as faculty members and
                        administrative staff. Our team comprises industry
                        veterans, seasoned educators, and subject matter experts
                        who bring a wealth of knowledge and experience to the
                        classroom. Through their guidance and mentorship, our
                        students receive unparalleled support as they embark on
                        their educational journey.{" "}
                        <button
                          className="text-[#296D9C] font-bold"
                          onClick={toggleExpanded2}
                        >
                          read less
                        </button>
                      </>
                    ) : (
                      <>
                        SIMTI is proud to attract some of the brightest minds in
                        the maritime industry to serve as faculty members and
                        administrative staff. Our..
                        <button
                          className="text-[#296D9C] font-bold"
                          onClick={toggleExpanded2}
                        >
                          read more
                        </button>
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex mb-4 flex-row items-center">
                <div>
                  <h1 className="font-bold">Affiliations and Partnerships</h1>
                  <p className="text-gray-400 text-justify md:pr-5">
                    {expanded3 ? (
                      <>
                        In collaboration with the Indian Maritime University and
                        leading industry stakeholders, SIMTI offers students
                        unparalleled opportunities for academic and professional
                        growth. Our strategic partnerships with prominent ship
                        owning and management companies worldwide provide
                        students with valuable insights and real-world
                        experience. Through these affiliations, SIMTI is
                        committed to ensuring the success and employability of
                        our graduates in the global maritime arena.{" "}
                        <button
                          className="text-[#296D9C] font-bold"
                          onClick={toggleExpanded3}
                        >
                          read less
                        </button>
                      </>
                    ) : (
                      <>
                        In collaboration with the Indian Maritime University and
                        leading industry stakeholders, SIMTI offers students
                        unparalleled opportunities for academic and professional
                        growth. Our..
                        <button
                          className="text-[#296D9C] font-bold"
                          onClick={toggleExpanded3}
                        >
                          read more
                        </button>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-[130%] hidden lg:block  bg-[#96c3e3]">
          <img
            src="/Images/Simt/Academic-building.jpeg"
            className=" object-contain"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default OurPrograms;
