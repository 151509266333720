import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

const ExpertiseArea = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const expertiseAreaRef = useRef(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const expertiseData = [
    {
      title: "Crude Oil Transport",
      description:
        "Large ocean-going oil tankers deployed to import parcels of crude oil into India for refining by Indian oil companies",
      imageSrc: "/Images/Ships/centuryTwo.jpeg",
      link: "/company/vesselprofile",
      stateValue: "Crude",
      carriers: "2",
    },
    {
      title: "Refined Oil Products Transport",
      description:
        "Workhorses of the SISL fleet and global oil product trade, deployed in transporting refined products and lube oil along the Indian coast and abroad",
      imageSrc: "/Images/Ships/Success.jpeg",
      link: "/company/vesselprofile",
      stateValue: "Product",
      carriers: "22",
    },
    {
      title: "LPG Transport",
      description:
        "Gas carriers deployed to import LPG into India to satisfy the country’s rapidly growing demand for the commodity",
      imageSrc: "/Images/Ships/LPGc_PineGas.jpeg",
      link: "/company/vesselprofile",
      stateValue: "Gas",
      carriers: "2",
    },
  ];

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent default right-click behavior
  };

  return (
    <div className="mx-4 my-10" id="areaOfExpertise" ref={expertiseAreaRef}>
      <h3 className="text-center md:text-start text-[#296D9C] md:text-3xl text-2xl font-normal mb-4">
        Our Area of Expertise
      </h3>
      <h1 className="text-center md:text-start text-3xl md:text-5xl">
        Navigating seas, connecting globally <br /> with precision and innovation
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-0 gap-5 mt-8">
        {expertiseData.map((item, index) => (
          <div
            key={index}
            className="relative"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className={`h-full w-full ${
                item.imageSrc === "/Images/Ships/LPGc_RoseGas.jpeg"
                  ? "md:rounded-r-2xl rounded-2xl"
                  : "rounded-none"
              } ${
                item.imageSrc === "/Images/Ships/centuryTwo.png"
                  ? "md:rounded-l-2xl rounded-2xl"
                  : "rounded-none"
              }`}
              src={item.imageSrc}
              alt={item.title}
              onContextMenu={handleContextMenu}
            />

            <Link to={`${item.link}?state=${item.stateValue}`}>
              {hoveredIndex === index && (
                <div className="absolute top-0 left-0 right-0 w-full h-full flex flex-col text-start justify-between bg-[#296D9C] bg-opacity-70 text-white p-4 cursor-pointer md:rounded-l-2xl md:rounded-r-2xl">
                  <div>
                    <div className="flex justify-between w-full">
                      <h3 className="text-xl font-normal mb-2 underline">
                        {item.title}
                      </h3>
                    </div>
                    <h3 className="text-md font-normal mb-2 ">
                      {item.carriers} Carriers
                    </h3>
                  </div>
                  <div>
                    <p className="text-sm py-8 pr-7">{item.description}</p>
                  </div>
                </div>
              )}
              {hoveredIndex !== index && (
                <div className="absolute top-0 left-0 w-full h-full flex flex-col text-start justify-between p-4">
                  <div className="flex justify-between w-full bg-black bg-opacity-45 p-2 rounded-md">
                    <h3 className="md:text-xl text-sm font-bold text-white mb- drop-shadow-md">
                      {item.title}
                    </h3>
                  </div>
                </div>
              )}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpertiseArea;
