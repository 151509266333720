import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
// import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import FooterCard from "./cards/FooterCard";
import JoinusCard from "../simt/JoinusCard";

const Footer = () => {
  const location = useLocation();
  const excludedRoutes = ["/getintouch", "/simti"];
  const shouldRenderFooterCard = !excludedRoutes.includes(location.pathname);
  const shouldRenderJoinUsFooter = location.pathname === "/simti";

  const navigate = useNavigate();

  const handleServicesClick = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    setTimeout(() => {
      const expertiseAreaElement = document.getElementById("areaOfExpertise");
      expertiseAreaElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };
  const handleInstituteClick = () => {
    if (location.pathname !== "/simti") {
      navigate("/simti");
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  return (
    <>
      <div className="mt-96 w-full">
        <div className="relative">
          <div className="h-auto md:h-full mt-[820px] md:mt-48  bg-[#21282D]">
            <div className="flex flex-col md:flex-row pt-32 px-10 md:h-80 h-full items-start font-extralight  ">
              <div className="w-full md:w-1/3 md:pr-20 p-4">
                <div className="flex flex-col items-center md:items-start">
                  <div className="flex flex-col sm:flex-row items-center mb-4 sm:mb-0">
                    <img
                      src="/assets/Logo.png"
                      alt="SISL Logo"
                      className="w-16 h-auto mb-2 sm:mb-0 sm:mr-4"
                    />
                    <h3 className="text-white font-medium text-lg md:text-xl text-center sm:text-left">
                      SEVEN ISLANDS SHIPPING LIMITED
                    </h3>
                  </div>
                  {/* <p className="text-white mt-4 text-center sm:text-left max-w-xs">
      Connecting Life by Anchoring Trust
    </p> */}
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row text-white items-start text-center md:text-start md:items-start justify-between px-8">
                <div className="w-full md:w-auto mb-6 md:mb-0">
                  <ul className="leading-10">
                    <li>
                      <Link
                        to="/#areaOfExpertise"
                        onClick={handleServicesClick}
                      >
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/clients"
                        // onClick={handleServicesClick}
                      >
                        Clients
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/simti" onClick={handleInstituteClick}>
                        Institute
                      </Link>
                    </li> */}
                    <li>
                      <a href="/company">About</a>
                    </li>
                  </ul>
                </div>
                <div className="w-full md:w-auto mb-6 md:mb-0">
                  <ul className="leading-10">
                    <li>
                      <a href="/company/gallery">Gallery</a>
                    </li>
                    <li>
                      <a href="/company/vesselprofile">Our Vessels</a>
                    </li>
                    <li>
                      <a href="/getintouch"> Contact Us</a>
                    </li>
                  </ul>
                </div>
                <hr className="border-t border-gray-600 md:hidden w-full my-8 md:my-0" />
                <div className="w-full md:w-52 flex flex-col md:items-start items-center ">
                  <ul>
               
                    <li className="flex md:flex-row flex-col gap-2 items-center mb-4">
                      <img
                        src="/assets/phone.png"
                        alt="Phone"
                        // className="mr-2"
                        style={{ width: "18px", height: "auto" }}
                      />
                      <a
                        href="tel:+91 22 4225 4225"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +91 22 4225 4225
                      </a>
                    </li>
                    <li className="flex md:flex-row flex-col gap-2 items-center mb-4">
                      <img
                        src="/assets/email.png"
                        alt="Email"
                        // className="mr-2"
                        style={{ width: "18px", height: "auto" }}
                      />
                      <a
                        href="mailto:contact@sishipping.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        sevenislands@sishipping.com
                      </a>
                    </li>
                    <li className="flex md:flex-row flex-col gap-2 items-center mb-4">
                      <img
                        src="/assets/location.png"
                        alt="Location"
                        // className="mr-2"
                        style={{ width: "18px", height: "auto" }}
                      />
                      <a
                        href="https://maps.app.goo.gl/WnCF5rg5H3x8enNQ6"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w- text-sm text-balance"
                      >
                        Suite 3A, 3B & 4, Level 8, B Wing, Times Square,
                        Andheri-Kurla Road, Andheri (E), Mumbai - 400059
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* line */}
            <hr className="border-t border-gray-600 my-8 md:my-0" />
            <div className="flex md:flex-row  flex-col gap-4 text-white justify-between py-4 px-10 items-center">
              <p className="font-extralight ">© 2024 SISL. All rights reserved.</p>
              <hr className="border-t border-gray-600 w-full md:hidden" />
              <div className="flex gap-2 ">
                <div>
                  <a
                    href="https://www.linkedin.com/company/seven-islands-shipping-ltd-/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/linked.png"
                      alt="linkedin"
                      style={{ width: "35px", height: "35px" }}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.facebook.com/profile.php?id=100082982989661&mibextid=YMEMSu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/facebook.png"
                      alt="facebook"
                      style={{ width: "35px", height: "35px" }}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/sevenislandsshipping?igsh=cG9uMjFxM25hNncy&utm_source=qr "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/instagram.png"
                      alt="Instagram"
                      style={{ width: "35px", height: "35px" }}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://twitter.com/Sevenislands12"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/x.png"
                      alt="x"
                      style={{ width: "35px", height: "35px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {shouldRenderFooterCard && <FooterCard />}
          {shouldRenderJoinUsFooter && <JoinusCard />}
        </div>
      </div>
    </>
  );
};

export default Footer;
