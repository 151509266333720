import React, { useState } from "react";
import { FaArrowDownLong } from "react-icons/fa6";
import AccidentGoal from "./AccidentGoal";
import AutomationGoal from "./AutomationGoal";
import EcoGoal from "./EcoGoal";
import { useMediaQuery } from "@react-hook/media-query";
import OurGoalMobile from "./OurGoalMobile";

const OurGoal = () => {
  const [selectedGoal, setSelectedGoal] = useState("ecoImpact");
  const isMobile = useMediaQuery("(max-width: 700px)");
  const isTab = useMediaQuery("(max-width: 900px)");

  const handleGoalClick = (goal) => {
    setSelectedGoal(goal);
  };
  return (
    <>
      {isMobile ? (
        <OurGoalMobile />
      ) : isTab ? (
        <div className="px-2 md:px-10 mb-24">
          <p className="text-[#296D9C] mb-5 md:text-3xl text-2xl">Our Goals</p>
          <div className="grid grid-cols-2">
            <div className="ourgoalright">
              <div className="ourgoalright">
                <div
                  className={`flex flex-col items-start cursor-pointer px-4 ${
                    selectedGoal === "ecoImpact"
                      ? "font-semibold"
                      : "animate-pulse"
                  }`}
                  onClick={() => handleGoalClick("ecoImpact")}
                >
                  <img
                    className="bg-[#eff8fe] p-4 rounded-full"
                    src="assets/environment.png"
                    alt="Environment"
                  />
                  <div className="">
                    <h1 className="text-xl">Lower Environmental Impact</h1>
                    <p className="text-[#686868] pr-28 ">
                      Our efforts focus on sustainable practices and
                      eco-friendly technologies
                    </p>
                  </div>
                  {/* {selectedGoal === "ecoImpact" && (
                    <div className="border p-2 rounded-full hover:animate-bounce  bg-[#afdcf7]">
                      <div className="">
                        <FaArrowDownLong
                          style={{ color: "#296D9C" }}
                          className="-rotate-90"
                        />
                      </div>
                    </div>
                  )} */}
                </div>

                <div
                  className={`flex flex-col items-start cursor-pointer px-4 ${
                    selectedGoal === "accidents"
                      ? "font-semibold"
                      : "animate-pulse"
                  }`}
                  onClick={() => handleGoalClick("accidents")}
                >
                  <img
                    className="bg-[#eff8fe] p-4 rounded-full"
                    src="/assets/recycle.png"
                    alt="recycle"
                  />
                  <div className="">
                    <h1 className="text-xl">Minimizing Incidents</h1>
                    <p className="text-[#686868]  pr-28">
                      Our unwavering commitment to safety prioritizes the
                      well-being of our team
                    </p>
                  </div>
                  {/* {selectedGoal === "accidents" && (
                    <div className="border p-2 rounded-full hover:animate-bounce bg-[#afdcf7]">
                      <div className="">
                        <FaArrowDownLong
                          style={{ color: "#296D9C" }}
                          className="-rotate-90"
                        />
                      </div>
                    </div>
                  )} */}
                </div>

                <div
                  className={`flex flex-col items-start cursor-pointer px-4 ${
                    selectedGoal === "automation"
                      ? "font-semibold"
                      : "animate-pulse"
                  }`}
                  onClick={() => handleGoalClick("automation")}
                >
                  <img
                    className="bg-[#eff8fe] p-4 rounded-full"
                    src="/assets/security.png"
                    alt="security"
                  />
                  <div className="">
                    <h1 className="text-xl "> Efficient Automation</h1>
                    <p className="text-[#686868] pr-28 ">
                      Our cutting-edge automation enhances productivity and
                      performance
                    </p>
                  </div>
                  {/* {selectedGoal === "automation" && (
                    <div className="border p-2 rounded-full hover:animate-bounce  bg-[#afdcf7]">
                      <div className="">
                        <FaArrowDownLong
                          style={{ color: "#296D9C" }}
                          className="-rotate-90"
                        />
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="ourgoalleft">
              {selectedGoal === "ecoImpact" && <EcoGoal />}
              {selectedGoal === "accidents" && <AccidentGoal />}
              {selectedGoal === "automation" && <AutomationGoal />}
            </div>
          </div>
        </div>
      ) : (
        <div className="px-2  md:px-10 mb-24">
          <p className="text-[#296D9C] mb-5 md:text-3xl text-2xl">Our Goals</p>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1">
            <div className="ourgoalright">
              <div
                className={`flex items-center cursor-pointer px-4 ${
                  selectedGoal === "ecoImpact" ? "font-bold" : "animate-pulse"
                }`}
                onClick={() => handleGoalClick("ecoImpact")}
              >
                <img
                  className="mr-5 bg-[#eff8fe] p-4 rounded-full"
                  src="assets/environment.png"
                  alt="Environment"
                />
                <div className="p-5">
                  <h1 className="text-xl md:text-3xl">
                    Lower Environmental Impact
                  </h1>
                  <p className="text-[#686868] pr-28 md:text-justify">
                    Our efforts focus on sustainable practices and eco-friendly
                    technologies
                  </p>
                </div>
                {selectedGoal === "ecoImpact" && (
                  <div className="border p-2 rounded-full hover:animate-bounce  bg-[#afdcf7]">
                    <div className="">
                      <FaArrowDownLong
                        style={{ color: "#296D9C" }}
                        className="-rotate-90"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`flex items-center cursor-pointer px-4 ${
                  selectedGoal === "accidents" ? "font-bold" : "animate-pulse"
                }`}
                onClick={() => handleGoalClick("accidents")}
              >
                <img
                  className="mr-7 bg-[#eff8fe] p-4 rounded-full"
                  src="/assets/recycle.png"
                  alt="recycle"
                />
                <div className="p-5">
                  <h1 className="text-xl md:text-3xl">Minimizing Incidents</h1>
                  <p className="text-[#686868] md:text-justify pr-28">
                    Our unwavering commitment to safety prioritizes the
                    well-being of our team
                  </p>
                </div>
                {selectedGoal === "accidents" && (
                  <div className="border p-2 rounded-full hover:animate-bounce bg-[#afdcf7]">
                    <div className="">
                      <FaArrowDownLong
                        style={{ color: "#296D9C" }}
                        className="-rotate-90"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`flex items-center cursor-pointer px-4 ${
                  selectedGoal === "automation" ? "font-bold" : "animate-pulse"
                }`}
                onClick={() => handleGoalClick("automation")}
              >
                <img
                  className="mr-5 bg-[#eff8fe] p-4 rounded-full"
                  src="/assets/security.png"
                  alt="security"
                />
                <div className="p-5">
                  <h1 className="text-xl md:text-3xl"> Efficient Automation</h1>
                  <p className="text-[#686868] pr-28 md:text-justify">
                    Our cutting-edge automation enhances productivity and
                    performance
                  </p>
                </div>
                {selectedGoal === "automation" && (
                  <div className="border p-2 rounded-full hover:animate-bounce  bg-[#afdcf7]">
                    <div className="">
                      <FaArrowDownLong
                        style={{ color: "#296D9C" }}
                        className="-rotate-90"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="ourgoalleft">
              {selectedGoal === "ecoImpact" && <EcoGoal />}
              {selectedGoal === "accidents" && <AccidentGoal />}
              {selectedGoal === "automation" && <AutomationGoal />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OurGoal;
