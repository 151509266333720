import React from "react";

const GetintouchTitle = () => {
  return (
    <>
      <div className="h-full flex flex-col gap-3 justify-between items-center relative pb-32 sm:mb-[1250px] md:mb-0 bg-[#296D9C]">
        <h1 className="text-4xl md:text-5xl font-medium mt-36 text-white">
          Get In Touch!
        </h1>
        <a
          href="mailto:contact@sishipping.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center gap-2 text-white">
            <img src="/assets/MailGT.png" alt="" />
            <h3>contact@sishipping.com</h3>
          </div>
        </a>
        <a
          href="tel:+91 22 4225 4225"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center gap-2 text-white">
            <img src="/assets/PhoneGt.png" alt="" />
            <h3>+91 22 4225 4225</h3>
          </div>
        </a>
      </div>
    </>
  );
};

export default GetintouchTitle;
