import React from "react";

const SmallScreenCards = () => {
  return (
    <div className="flex flex-col gap-4 p-6 bg-blue-200">
      <div className="bg-white  text-md text-center p-6 shadow-md rounded-xl text-md text-gray-600 md:text-justify">
      <div className="flex flex-col items-center">
          <img
            className="mx-4"
            src="/assets/Cuttingedge.png"
            alt=""
            style={{ width: "45px", height: "45px" }}
          />
          <h3 className="font-bold text-black">Our Quality</h3>
          <p className="text-sm">
            ISO 9001:2015 – Global quality management for consistent practices
            and delivery
          </p>
        </div>
      </div>
      <div className="bg-white  text-md text-center p-6 shadow-md rounded-xl text-md text-gray-600 md:text-justify">
        <div className="flex flex-col items-center">
          <img
            className="mx-4"
            src="/assets/Safety.png"
            alt=""
            style={{ width: "45px", height: "45px" }}
          />
          <h3 className="font-bold text-black">Safety Commitment</h3>
          <p className="text-sm">
            Unwavering commitment to safety by implementing stringent measures
            to minimize accident risks
          </p>
        </div>
      </div>
      <div className="bg-white  text-md text-center p-6 shadow-md rounded-xl text-md text-gray-600 md:text-justify">
        <div className="flex flex-col items-center">
          <img
            className="mx-4"
            src="/assets/Industry.png"
            alt=""
            style={{ width: "45px", height: "45px" }}
          />
          <h3 className="font-bold text-black">Leadership</h3>
          <p className="text-sm">
            Redefining excellence in the maritime industry through innovation
            and reliability
          </p>
        </div>
      </div>
    </div>
  );
};

export default SmallScreenCards;
