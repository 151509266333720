import React, { useState, useEffect } from "react";
import { BaseUrl, Login, Ranks, Officers } from "../../constants";
import ApplyForm from "./ApplyForm";

const ApplyNow = () => {
  const [Deck, SetDeck] = useState([]);
  const [Engine, SetEngine] = useState([]);
  const [token, setToken] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("apply");

  const authenticate = async () => {
    try {
      const response = await fetch(`${BaseUrl}${Login}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: "sisadmin",
          password: "dEv@$1$@2024##",
        }),
      });

      if (!response.ok) throw new Error("Authentication failed");

      const data = await response.json();
      setToken(data.token);
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };

  const getOfficers = async () => {
    if (!token) return;

    try {
      const response = await fetch(
        "https://careerswebapi.sishipping.com:1110/api/home/get-ranks-and-sourcing-officer-details",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            deckEngine: "0",
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to fetch ranks");

      const data = await response.json();
      console.log(data);

      const Deckofficers = data.data
        .filter((officer) => officer.deckEngine.trim() === "DECK")
        .map((deckOfficer) => ({
          rankCd: deckOfficer.rankCd,
          rankName: deckOfficer.rankName,
          sourcingOfficerName: deckOfficer.sourcingOfficerName,
          sourcingOfficerMobileNumber: deckOfficer.sourcingOfficerMobileNumber,
          sourcingOfficerEmailId: deckOfficer.sourcingOfficerEmailId,
        }));

      const Engineofficers = data.data
        .filter((officer) => officer.deckEngine.trim() === "ENGINE")
        .map((engineOfficer) => ({
          rankCd: engineOfficer.rankCd,
          rankName: engineOfficer.rankName,
          sourcingOfficerName: engineOfficer.sourcingOfficerName,
          sourcingOfficerMobileNumber:
            engineOfficer.sourcingOfficerMobileNumber,
          sourcingOfficerEmailId: engineOfficer.sourcingOfficerEmailId,
        }));

      SetDeck(Deckofficers);
      SetEngine(Engineofficers);

      console.log(Deckofficers, "Deck Officers");
      console.log(Engineofficers, "Engine Officers");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  useEffect(() => {
    getOfficers();
  }, [token]);

  return (
    <div className="w-full min-h-screen bg-gray-200 px-4 py-8">
      <div className="max-w-7xl mx-auto">
        <div className="md:hidden mb-4">
          <div className="flex justify-between gap-1 w-full">
            <button
              className={` ${
                activeTab === "deck"
                  ? "bg-blue-500 text-white rounded-lg"
                  : "bg-gray-300 rounded-lg"
              }`}
              onClick={() => setActiveTab("deck")}
            >
              Deck/Saloon Ranks
            </button>
            <button
              className={`p-2 ${
                activeTab === "apply"
                  ? "bg-blue-500 text-white rounded-lg"
                  : "bg-gray-300 rounded-lg"
              }`}
              onClick={() => setActiveTab("apply")}
            >
              Apply Form
            </button>
            <button
              className={`p-2 ${
                activeTab === "engine"
                  ? "bg-blue-500 text-white rounded-lg"
                  : "bg-gray-300 rounded-lg"
              }`}
              onClick={() => setActiveTab("engine")}
            >
              Engine Ranks
            </button>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Table - Engine Officers */}
          <div className="w-full lg:w-1/3">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              {/* Desktop Table */}
              <div className="hidden md:block overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-[#4087ba] border-b text-white">
                      <th colSpan="2" className="p-3 text-center font-bold">
                        Deck/Saloon Side Ranks
                      </th>
                    </tr>
                    <tr className="bg-[#4087ba] text-white">
                      <div>
                        <th className="p-3 text-left font-bold whitespace-nowrap ">
                          List of Ranks
                        </th>
                        <th className="p-3 text-left font-bold whitespace-nowrap">
                          Sourcing Officer Details
                        </th>
                      </div>
                    </tr>
                  </thead>
                  <tbody>
                    {Deck.map((officer, index) => (
                      <tr key={index} className="border-t hover:bg-gray-50">
                        <div className="flex justify-start items-center w-full">
                          <td className="p-3 w-1/3 text-black ">
                            {officer.rankName}
                          </td>
                          <td className="p-3 w-2/3">
                            <div className="space-y-1">
                              <div className="font-medium">
                                {officer.sourcingOfficerName}
                              </div>
                              <div className="text-sm text-gray-600">
                                <a
                                  href={`tel:${officer.sourcingOfficerMobileNumber}`}
                                  className="hover:underline"
                                >
                                  {officer.sourcingOfficerMobileNumber}
                                </a>
                              </div>
                              <div className="text-sm text-gray-600 break-all">
                                <a
                                  href={`mailto:${officer.sourcingOfficerEmailId}`}
                                  className="hover:underline"
                                >
                                  {officer.sourcingOfficerEmailId}
                                </a>
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Mobile Cards */}
              {activeTab === "deck" && (
                <div className="md:hidden">
                  {Deck.map((officer, index) => (
                    <div key={index} className="p-4 border-b last:border-b-0">
                      <div className="text-sm font-bold text-[#4087ba] mb-2">
                        Deck/Saloon Side Ranks
                      </div>
                      <div className="space-y-2">
                        <div>
                          <span className="font-semibold">Rank:</span>{" "}
                          {officer.rankName}
                        </div>
                        <div className="space-y-1">
                          <div className="font-semibold">Sourcing Officer:</div>
                          <div className="pl-2">
                            <div>{officer.sourcingOfficerName}</div>
                            <div className="text-sm text-gray-600">
                                <a
                                  href={`tel:${officer.sourcingOfficerMobileNumber}`}
                                  className="hover:underline"
                                >
                                  {officer.sourcingOfficerMobileNumber}
                                </a>
                              </div>
                              <div className="text-sm text-gray-600 break-all">
                                <a
                                  href={`mailto:${officer.sourcingOfficerEmailId}`}
                                  className="hover:underline"
                                >
                                  {officer.sourcingOfficerEmailId}
                                </a>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Center Form Section */}
          {activeTab === "apply" && (
            <ApplyForm
              token={token}
              onClose={() => setIsModalOpen(false)}
              className="bg-white rounded-lg shadow-md p-6"
            />
          )}

          {/* Right Table - Deck Officers */}
          <div className="w-full lg:w-1/3">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              {/* Larg screen Table */}
              <div className="hidden md:block overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-[#4087ba] border-b text-white">
                      <th colSpan="2" className="p-3 text-center font-bold">
                        Engine Side Ranks
                      </th>
                    </tr>
                    <tr className="bg-[#4087ba] text-white">
                      <div>
                        <th className="p-3 text-left font-bold whitespace-nowrap ">
                          List of Ranks
                        </th>
                        <th className="p-3 text-left font-bold whitespace-nowrap">
                          Sourcing Officer Details
                        </th>
                      </div>
                    </tr>
                  </thead>
                  <tbody>
                    {Engine.map((officer, index) => (
                      <tr key={index} className="border-t hover:bg-gray-50">
                        <div className="flex justify-start items-center w-full">
                          <td className="p-3 w-1/3 text-black ">
                            {officer.rankName}
                          </td>
                          <td className="p-3 w-2/3">
                            <div className="space-y-1">
                              <div className="font-medium">
                                {officer.sourcingOfficerName}
                              </div>
                              <div className="text-sm text-gray-600">
                                <a
                                  href={`tel:${officer.sourcingOfficerMobileNumber}`}
                                  className="hover:underline"
                                >
                                  {officer.sourcingOfficerMobileNumber}
                                </a>
                              </div>
                              <div className="text-sm text-gray-600 break-all">
                                <a
                                  href={`mailto:${officer.sourcingOfficerEmailId}`}
                                  className="hover:underline"
                                >
                                  {officer.sourcingOfficerEmailId}
                                </a>
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Mobile Cards */}
              {activeTab === "engine" && (
                <div className="md:hidden">
                  {Engine.map((officer, index) => (
                    <div key={index} className="p-4 border-b last:border-b-0">
                      <div className="text-sm font-bold text-[#4087ba] mb-2">
                        Engine Side Ranks
                      </div>
                      <div className="space-y-2">
                        <div>
                          <span className="font-semibold">Rank:</span>{" "}
                          {officer.rankName}
                        </div>
                        <div className="space-y-1">
                          <div className="font-semibold">Sourcing Officer:</div>
                          <div className="pl-2">
                            <div>{officer.sourcingOfficerName}</div>
                            <div className="text-sm text-gray-600">
                                <a
                                  href={`tel:${officer.sourcingOfficerMobileNumber}`}
                                  className="hover:underline"
                                >
                                  {officer.sourcingOfficerMobileNumber}
                                </a>
                              </div>
                              <div className="text-sm text-gray-600 break-all">
                                <a
                                  href={`mailto:${officer.sourcingOfficerEmailId}`}
                                  className="hover:underline"
                                >
                                  {officer.sourcingOfficerEmailId}
                                </a>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <SuccessModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default ApplyNow;

const SuccessModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000); // Close after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4 text-[#296D9C]">Success!</h2>
        <p>Your form has been successfully submitted.</p>
      </div>
    </div>
  );
};
