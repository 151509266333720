import React from "react";
import { useMediaQuery } from "@react-hook/media-query";

const AutomationGoal = () => {
  const isMobile = useMediaQuery("(max-width: 700px)");
  const isTab = useMediaQuery("(max-width: 900px)");

  return (
    <>
      {isMobile ? (
        <div>
          <div className="ourgoalleft py-5">
            <p className="text-3xl font-medium">
              Driving efficiency through technology
            </p>
            <p className="para text-xl text-justify pt-4">
              In our quest to stay ahead of the technology curve, we implement
              cutting-edge software to enhance productivity and performance. Our
              continuously improving systems streamline processes, optimize
              resource allocation, and minimize human errors, allowing us to
              operate with improved efficiency. From vessel tracking and route
              optimization to cargo handling and logistics management, our
              technology initiatives enable seamless operations, helping us to
              deliver superior service to our valued clients.
            </p>
          </div>
        </div>
      ) : isTab ? (
        <div>
          <div className="ourgoalleft py-5 my-16">
            <p className="text-3xl font-medium">
              Driving efficiency through technology
            </p>
            <p className="para text-base pt-2 text-justify">
              In our quest to stay ahead of the technology curve, we implement
              cutting-edge software to enhance productivity and performance. Our
              continuously improving systems streamline processes, optimize
              resource allocation, and minimize human errors, allowing us to
              operate with improved efficiency. From vessel tracking and route
              optimization to cargo handling and logistics management, our
              technology initiatives enable seamless operations, helping us to
              deliver superior service to our valued clients.
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="ourgoalleft py-5">
            <p className="md:leading-[60px] md:text-5xl lg:leading-[40px] lg:text-4xl text-3xl font-medium">
              Driving efficiency through technology
            </p>
            <p className="para text-xl text-justify pt-4">
              In our quest to stay ahead of the technology curve, we implement
              cutting-edge software to enhance productivity and performance. Our
              continuously improving systems streamline processes, optimize
              resource allocation, and minimize human errors, allowing us to
              operate with improved efficiency. From vessel tracking and route
              optimization to cargo handling and logistics management, our
              technology initiatives enable seamless operations, helping us to
              deliver superior service to our valued clients.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AutomationGoal;
