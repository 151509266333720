import React, { useState } from "react";
const OurGoalMobile = () => {
  const [selectedGoal, setSelectedGoal] = useState(null);

  const handleGoalClick = (goal) => {
    setSelectedGoal(selectedGoal === goal ? null : goal);
  };
  return (
    <>
      <div className="px-2 md:px-10 mb-24">
        <p className="text-[#296D9C] text-2xl mb-5">Our Goals</p>
        <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1">
          <div className="ourgoalright">
            <div
              className={`flex md:flex-row flex-col md:text-start text-center items-center cursor-pointer ${
                selectedGoal === "ecoImpact" ? "font-bold" : ""
              }`}
              onClick={() => handleGoalClick("ecoImpact")}
            >
              <img
                className="bg-[#eff8fe] p-4 rounded-full"
                src="/assets/environment.png"
                alt="Environment"
              />
              <div className="p-5">
                <h1 className="text-xl md:text-3xl">
                  Lower Environmental Impact
                </h1>
                <p className="text-[#686868]">
                  Our efforts focus on sustainable practices and <br />{" "}
                  eco-friendly technologies.
                </p>
              </div>
            </div>
            <div className="ourgoalleft">
              {selectedGoal === "ecoImpact" && (
                <div className="p-5">
                  <p className="text-[#686868] text-sm text-justify">
                    We are committed to minimizing our environmental footprint
                    through sustainable practices and eco-friendly technologies.
                    As a leading oil shipping company, we understand the
                    importance of protecting our planet for future generations.
                    Our efforts focus on reducing emissions, optimizing fuel
                    efficiency and adopting greener energy sources wherever
                    possible. We continuously explore solutions to lower our
                    carbon footprint and promote environmental stewardship
                    throughout our operations.
                  </p>
                </div>
              )}
            </div>
            <div
              className={`flex md:flex-row flex-col md:text-start text-center items-center cursor-pointer ${
                selectedGoal === "Accidents" ? "font-bold" : ""
              }`}
              onClick={() => handleGoalClick("Accidents")}
            >
              <img
                className=" bg-[#eff8fe] p-4 rounded-full"
                src="/assets/security.png"
                alt="Environment"
              />
              <div className="p-5">
                <h1 className="text-xl md:text-3xl">Minimizing Incidents</h1>
                <p className="text-[#686868]">
                  Our unwavering commitment to safety prioritizes the well
                  -being of our team.
                </p>
              </div>
            </div>
            <div className="ourgoalleft">
              {selectedGoal === "Accidents" && (
                <div className="p-2">
                  <p className="text-[#686868] text-justify text-sm">
                    Safety is our top priority. We have an unwavering commitment
                    to ensuring the well-being of our team and maintaining a
                    secure workplace. Through comprehensive training programs,
                    stringent safety protocols, and proactive risk management,
                    we cultivate a culture of safety that permeates every aspect
                    of our operations. Our dedicated safety professionals work
                    tirelessly to identify and mitigate potential hazards,
                    ensuring that our employees return home safely at the end of
                    work terms.
                  </p>
                </div>
              )}
            </div>
            <div
              className={`flex md:flex-row flex-col md:text-start text-center items-center cursor-pointer ${
                selectedGoal === "Automation" ? "font-bold" : ""
              }`}
              onClick={() => handleGoalClick("Automation")}
            >
              <img
                className="bg-[#eff8fe] p-4 rounded-full"
                src="/assets/recycle.png"
                alt="Environment"
              />
              <div className="p-2">
                <h1 className="text-xl md:text-3xl">Efficient Automation</h1>
                <p className="text-[#686868]">
                  Our Cutting-edge automation enhances productivity and
                  performance.
                </p>
              </div>
            </div>
            <div className="ourgoalleft">
              {selectedGoal === "Automation" && (
                <div className="p-2">
                  <p className="text-[#686868] text-justify text-sm">
                    In our quest to stay ahead of the technology curve, we
                    implement cutting-end software to enhance productivity and
                    performance. Our continuously improving systems streamline
                    processes, optimize resource allocation, and minimize human
                    errors, allowing us to operate with improved efficiency.
                    From vessel tracking and route optimization to cargo
                    handling and logistics management, our technology
                    initiatives enable seamless operations, helping us to
                    deliver superior service to our valued clients
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurGoalMobile;
