import React, { useState, useEffect, useRef } from "react";

const SafetyProtocol = () => {
  const [expanded, setExpanded] = useState(false);
  const expandedRef = useRef(null);

    const handleContextMenu = (event) => {
      event.preventDefault();
    };


  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleClickOutside = (event) => {
    if (expandedRef.current && !expandedRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="md:px-10 px-2 bg-[#F2FAFF]">
        <p className="text-[#296D9C] md:text-3xl text-2xl pt-14 mb-6">
          Safety Protocol
        </p>
        <div className="grid lg:grid-cols-2 md:grid-cols-2">
          <div className="safetyprotocolleft">
            <p className="pb-9 font-medium md:text-3xl lg:text-4xl text-2xl">
              We cultivate a healthy and secure environment
            </p>
            <img
              className="w-[500px] h-[300px] object-cover object-top rounded-[20px] mb-3"
              src="/Images/SafetyProtocol2.png"
              alt="Safety Protocol"
              onContextMenu={handleContextMenu}
            />
          </div>
          <div
            ref={expandedRef}
            className="safetyprotocolright md:px-6 lg:px-6 content-end mb-4"
          >
            <p className="md:text-xl text-justify ">
              We, at SISL, have an established standard with implemented polices
              to ensure that our fleet is compliant with all Flag State,
              Classification Society, Local & International Rules and
              Regulations that could jeopardize safety, pollution prevention and
              efficient operations, if not followed correctly. This includes
              providing the necessary shore-based support and assistance. In
              order to achieve this, SISL has an ISM Department, headed by our
              highly skilled and qualified DPA (Designated Person Ashore) and
              ISM Manager, who monitors the fleet and its crew at all times. The
              ISM department ensures that within SISL, employees work in a
              healthy, safe and secure environment. We also closely monitor the
              regulations that will be coming into force in the near future and
              pro-actively ensure compliance with them.
            </p>
            {/* <p className="para leading-[20px] md:text-lg text-sm pt-5">
              To ensure compliance, our skilled ISM Department, led by a
              qualified DPA and ISM Manager, monitors our fleet and crew,
              fostering a healthy and secure work environment.
             
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SafetyProtocol;
