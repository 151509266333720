import React from 'react'

const LeaderHead = () => {
  return (
    <>
    <div className="flex flex-col items-center relative text-center py-16 w-full bg-[#296D9C]">
       <h1 className="text-4xl font-semibold mb-2 text-white">Leadership</h1>
       {/* <p className="text-white">
       Browse through our Documents of Compliance and Quality Certificates
       </p> */}
     </div>
   </>
  )
}

export default LeaderHead
