import React,{useState,useEffect,useMemo} from "react";
import { Link } from "react-router-dom";
import "swiper/css/bundle";
import "swiper/css/effect-fade";


const TopNav= () =>  {
  const texts = useMemo(
    () => [
      "Crew Requirements on Crude and Product Tankers",
      "Crew Requirements on Coastal-Trading and Foreign-Going Tankers",
      "For more details, click here!",
    ],
    []
  );

  const [currentText, setCurrentText] = useState(texts[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText(texts[currentIndex]);
      setCurrentIndex((currentIndex + 1) % texts.length);
    }, 3300);
    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, texts]);

  return (
    <div
      id="sticky-banner"
      className="sticky z-[1050] top-0 left-0 right-0 flex justify-between border-b h-8 bg-[#4087ba] border-gray-200"
    >
      <div className="flex items-center mx-auto">
        <Link to={"/career"}>
          <p className="flex items-center  font-semibold text-white cursor-pointer">
            <span className="md:text-lg text-[9px]">{currentText}</span>
          </p>
        </Link>
      </div>
    </div>
  );
}
export default TopNav