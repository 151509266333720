import React from "react";


const GalleryTitle = () => {
  return (
    <>
      <div className="flex flex-col items-center relative text-center py-12 bg-[#296D9C]">
        <h1 className="text-4xl font-semibold mb-2 text-white">Gallery</h1>
        <p className="text-white">
          Explore moments and images captured at sea, showcasing the diversity  <br />
        of the Seven Islands fleet
        </p>
      </div>
    </>
  );
};

export default GalleryTitle;
