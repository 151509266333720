import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import LandingHead from "./components/common/LandingHead";
import CareerTitle from "./components/career/CareerTitle";
import TopNav from "./components/common/TopNav";
import CompanyTitle from "./components/company/CompanyTitle";
import SimtTitle from "./components/simt/SimtTitle";
import GetintouchTitle from "./components/letstalk/GetintouchTitle";
import GalleryTitle from "./components/company/Gallery/GalleryTitle";
import ClientTitle from "./components/clients/ClientTitle";
import Footer from "./components/common/Footer";
import ExpertiseArea from "./components/common/ExpertiseArea";
import OurGoal from "./components/common/Goals/OurGoal";
import OurPrograms from "./components/simt/OurPrograms";
import SafetyProtocol from "./components/common/SafetyProtocol";
import ImageComp from "./components/clients/ImageComp";
import ApplyNow from "./components/apply/ApplyNow";
import LeaderHead from "./components/company/LeaderHead";
import DoorDashFavorite from "./Loader";
import { SpeedInsights } from "@vercel/speed-insights/react";

import Sticky from "./components/common/Sticky";
import "./App.css";
import "./index.css";
import StickyMobile from "./components/common/StickyMobile";

// Lazy load components
const GlobalConnect = lazy(() => import("./components/clients/GlobalConnect"));
// const ClientsReview = lazy(() => import("./components/clients/ClientsReview"));
// const Brands = lazy(() => import("./components/clients/Brands"));
const Careers = lazy(() => import("./components/career/Careers"));
const OurVision = lazy(() => import("./components/company/OurVision"));
const OurMission = lazy(() => import("./components/company/OurMission"));
const Corporate = lazy(() => import("./components/company/Corporate"));
const Executives = lazy(() => import("./components/company/Executives"));
const Board = lazy(() => import("./components/company/Board"));
const Certificaton = lazy(() => import("./components/company/Certificaton"));
const Milestones = lazy(() =>
  import("./components/company/Milestone/Milestones")
);
const GalleryImages = lazy(() =>
  import("./components/company/Gallery/GalleryImages")
);
// const Description = lazy(() => import("./components/company/Description"));
const VesselProfile = lazy(() =>
  import("./components/company/Vessels/VesselProfile")
);
const GetIntouchCard = lazy(() =>
  import("./components/letstalk/GetIntouchCard")
);

const LazyInvestorCorner = lazy(() =>
  import("./components/common/RegulatoryCompliance/InvestorCorner")
);
const App = () => {
  return (
    <div className="App">
      <Router>
        <TopNav />
        <StickyMobile />
        <Sticky />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Suspense fallback={<DoorDashFavorite />}>
                <LandingHead/>
                  {/* <MainCards /> */}
                  <ExpertiseArea />
                  <OurGoal />
                  <SafetyProtocol />
                  {/* <InvestorCorner /> */}
                  
                </Suspense>
              </>
            }
          />
          <Route
            path="/career"
            element={
              <Suspense fallback={<DoorDashFavorite />}>
                <CareerTitle/>
                <Careers />
              </Suspense>
            }
          />
          <Route
            path="/clients"
            element={
              <Suspense fallback={<DoorDashFavorite />}>
                <ClientTitle/>
                <ImageComp />
                <GlobalConnect />
              </Suspense>
            }
          />
          <Route
            path="/getintouch"
            element={
              <Suspense fallback={<DoorDashFavorite />}>
                <GetintouchTitle/>
                <GetIntouchCard />
              </Suspense>
            }
          />
          <Route path="/company" element={<Outlet />}>
            <Route
              index={true}
              element={
                <Suspense fallback={<DoorDashFavorite />}>
                  <>
                    {/* <Description /> */}
                    <CompanyTitle/>
                    <Milestones />
                    <OurVision />     
                    <OurMission />
                    <Corporate />

                    {/* <VideoComponent/> */}
                  </>
                </Suspense>
              }
            />
            <Route
              path="gallery"
              element={
                <Suspense fallback={<DoorDashFavorite />}>
                  <GalleryTitle/>
                  <GalleryImages />
                </Suspense>
              }
            />
            <Route
              path="vesselprofile"
              element={
                <Suspense fallback={<DoorDashFavorite />}>
                  <VesselProfile />
                </Suspense>
              }
            />
            <Route
              path="corporate"
              element={
                <Suspense fallback={<DoorDashFavorite />}>
                  <Corporate />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/compliance"
            element={
              <Suspense fallback={<DoorDashFavorite />}>
                <LazyInvestorCorner />
                <Certificaton />
              </Suspense>
            }
          />
          <Route
            path="/leadership"
            element={
              <Suspense fallback={<DoorDashFavorite />}>
                <LeaderHead/>
                <Board />
                <Executives />
              </Suspense>
            }
          />
          <Route />
          <Route
            path="/simti"
            element={
              <Suspense fallback={<DoorDashFavorite />}>
                <SimtTitle/>
                <OurPrograms />
              </Suspense>
            }
          />
          <Route
            path="/apply"
            element={
              <Suspense fallback={<DoorDashFavorite />}>
                <ApplyNow />
              </Suspense>
            }
          />
           <Route
            path="/careers_apply"
            element={
              <Suspense fallback={<DoorDashFavorite />}>
                <ApplyNow />
              </Suspense>
            }
          />
          <Route />
        </Routes>
        <Footer />
      </Router>
      <SpeedInsights />
    </div>
  );
};

export default App;
