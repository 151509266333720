import React from "react";
import { useMediaQuery } from "@react-hook/media-query";

const AccidentGoal = () => {
  const isMobile = useMediaQuery("(max-width: 700px)");
  const isTab = useMediaQuery("(max-width: 900px)");

  return (
    <>
      {isMobile ? (
        <div>
          <div className="ourgoalleft py-5">
            <p className="text-3xl font-medium">Safety is our top priority</p>
            <p className="para text-xl md:text-xl pt-4 text-justify">
              We have an unwavering commitment to ensuring the well-being of our
              team and maintaining a secure workplace. Through comprehensive
              training programs, stringent safety protocols and proactive risk
              management, we cultivate a culture of safety that permeates every
              aspect of our operations. Our dedicated safety professionals work
              tirelessly to identify and mitigate potential hazards, ensuring
              that our employees return home safely at the end of work terms.
            </p>
          </div>
        </div>
      ) : isTab ? (
        <div>
          <div className="ourgoalleft py-5 my-16">
            <p className="text-3xl font-medium">Safety is our top priority</p>
            <p className="para text-base pt-2 text-justify">
              We have an unwavering commitment to ensuring the well-being of our
              team and maintaining a secure workplace. Through comprehensive
              training programs, stringent safety protocols and proactive risk
              management, we cultivate a culture of safety that permeates every
              aspect of our operations. Our dedicated safety professionals work
              tirelessly to identify and mitigate potential hazards, ensuring
              that our employees return home safely at the end of work terms.
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="ourgoalleft py-5">
            <p className="md:leading-[60px] md:text-5xl lg:leading-[40px] lg:text-4xl text-3xl font-medium">
              Safety is our top priority
            </p>
            <p className="para text-xl md:text-xl pt-4 text-justify">
              We have an unwavering commitment to ensuring the well-being of our
              team and maintaining a secure workplace. Through comprehensive
              training programs, stringent safety protocols and proactive risk
              management, we cultivate a culture of safety that permeates every
              aspect of our operations. Our dedicated safety professionals work
              tirelessly to identify and mitigate potential hazards, ensuring
              that our employees return home safely at the end of work terms.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AccidentGoal;
