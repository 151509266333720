import React from "react";
import { Img } from "react-image";


const ImageComp = () => {
    const handleContextMenu = (event) => {
      event.preventDefault(); 
    };
  return (
    <div className="h-auto md:h-96 mt-6 mx-2 text-zinc-950">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-9 md:h-36">
        <Img
          src="/Images/Clients/HarborLights.jpg"
          alt="Harborlights"
          className="w-full h-full object-cover"
          onContextMenu={handleContextMenu}
        />
        <Img
          src="/Images/Clients/PetroleumRefinery.jpg"
          alt="Petroleum Refinery"
          className="w-full h-full object-cover"
          onContextMenu={handleContextMenu}
        />
        <Img
          src="/Images/Clients/PortBlur.jpg"
          alt="Harborlights"
          className="w-full h-full object-cover"
          onContextMenu={handleContextMenu}
        />
      </div>
    </div>
  );
};

export default ImageComp;
