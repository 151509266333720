import { useEffect } from "react";

const ClientTitle = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-cover bg-center bg-no-repeat " style={{backgroundImage: "url('/Images/PetroleumRefinery.jpg')"}}>
      <div className="md:h-[530px] h-full flex flex-col justify-between items-center relative z-10 bg-black bg-opacity-50">
        <div className="text-white rounded-lg p-4 md:p-10">
          <h1 className="text-3xl md:text-5xl font-medium text-center mt-14 p-4 md:p-10">
            <span className="">Entrenched Partnerships:</span> Our <br /> Trusted
            Clients in Global Shipping
          </h1>
          <p className="text-lg md:text-xl mb-8 md:px-24 text-center">
            Seven Islands Shipping caters to Indian coastal and international
            movement of liquid and gas cargoes for all the domestic oil majors
            as well as large international oil producers and traders. The
            company maintains an optimal mix of its vessel capacity on time and
            voyage charters in response to market conditions. SISL's vessels
            globally transport crude oil, petroleum products, lube oil, LPG and
            palm oils.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClientTitle;