import React from 'react';
import { useMediaQuery } from "@react-hook/media-query";

const EcoGoal = () => {
  const isMobile = useMediaQuery("(max-width: 700px)");
  const isTab = useMediaQuery("(max-width: 900px)");

  return (
    <>
      {isMobile ? (
        <div>
          <div className="ourgoalleft py-5">
            <p className=" text-3xl font-medium">
              Pioneering excellence <br /> and innovation towards our vision
            </p>
            <p className="para text-xl md:text-xl pt-4 text-justify">
              We are committed to minimizing our environmental footprint through
              sustainable practices and eco-friendly technologies. As a leading
              oil shipping company, we understand the importance of protecting
              our planet for future generations. Our efforts focus on reducing
              emissions, optimizing fuel efficiency and adopting greener energy
              sources wherever possible. We continuously explore solutions to
              lower our carbon footprint and promote environmental stewardship
              throughout our operations.
            </p>
          </div>
        </div>
      ) : isTab ? (
        <div>
          <div className="ourgoalleft py-5 my-16">
            <p className="text-3xl font-medium">
              Pioneering excellence <br /> and innovation towards our vision
            </p>
            <p className="para text-base  pt-2 text-justify">
              We are committed to minimizing our environmental footprint through
              sustainable practices and eco-friendly technologies. As a leading
              oil shipping company, we understand the importance of protecting
              our planet for future generations. Our efforts focus on reducing
              emissions, optimizing fuel efficiency and adopting greener energy
              sources wherever possible. We continuously explore solutions to
              lower our carbon footprint and promote environmental stewardship
              throughout our operations.
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="ourgoalleft py-5">
            <p className="md:leading-[60px] md:text-5xl lg:leading-[40px] lg:text-4xl text-3xl font-medium">
              Pioneering excellence <br /> and innovation towards our vision
            </p>
            <p className="para text-xl md:text-xl pt-4 text-justify">
              We are committed to minimizing our environmental footprint through
              sustainable practices and eco-friendly technologies. As a leading
              oil shipping company, we understand the importance of protecting
              our planet for future generations. Our efforts focus on reducing
              emissions, optimizing fuel efficiency and adopting greener energy
              sources wherever possible. We continuously explore solutions to
              lower our carbon footprint and promote environmental stewardship
              throughout our operations.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default EcoGoal;