import React from "react";

const CareerTitle = () => {
  return (
    <div className="relative w-full h-full mb-4">
      {/* Background Image */}
      <img
        className="w-full h-full object-fill hidden md:block"
        src="/Images/wheelhouse.jpeg"
        alt="Hero Background"
      />
      {/* Mobile Background Image */}
      <img
        className="w-full h-screen object-cover block md:hidden"
        src="/Images/CareerMob.png"
        alt="Mobile Background"
      />

      {/* Overlaying Text and Buttons */}
      <div className="absolute inset-0 lg:-top-[80px] -top-[120px] flex flex-col items-start justify-start text-start text-white px-4">
        {/* Responsive text size */}
        <h1 className="text-3xl md:text-5xl font-medium mt-32 px-5">
          Sail towards a rewarding  career today
        </h1>
        {/* <div className="flex space-x-4"> */}
          <p className="text-lg md:text-lg my-3  px-5 text-justify md:w-[70%]">
            Unlock your potential with a career at Seven Islands Shipping
            Limited. Join a dynamic team committed to excellence, innovation and
            growth in the maritime industry
          </p>
        {/* </div> */}
      </div>
    </div>
  );
};

export default CareerTitle;
