import React from "react";
import { Link } from "react-router-dom";

const JoinusCard = () => {
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <div className="absolute left-0 right-0 -top-[650px] sm:-top-[690px] md:-top-[340px] mx-auto max-w-[1440px] lg:max-w-[1100px] md:max-w-[800px] sm:max-w-[600px]">
      <div className="grid grid-cols-1 md:grid-cols-2 mx-4 md:mx-12">
        <div className="flex flex-col items-center md:items-start bg-[#296D9C] justify-between text-white md:rounded-l-2xl rounded-t-2xl md:rounded-r-none p-8 md:p-4 lg:p-10 text-center md:text-start ">
          <h2 className="md:text-base sm:text-2xl text-sm mb-4 text-justify ">
            At SIMTI, we are dedicated to fostering a culture of excellence,
            innovation, and ethical leadership in the maritime industry. By
            nurturing talent, advancing knowledge, and promoting industry best
            practices, we aim to make a significant contribution to the maritime
            community and the larger task of nation-building. Join us at SIMTI
            and embark on a rewarding journey towards a successful career in the
            maritime sector.
          </h2>
          <Link
            to="https://simtinstitute.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="flex items-center gap-2 p-2 rounded-lg bg-[#FF8730] hover:bg-yellow-500 text-white w-44 justify-center">
              VISIT US
              <img
                src="/assets/letsTalk.png"
                alt="Lets Talk"
                className="w-4 h-4"
              />
            </button>
          </Link>
        </div>
   
        <div className="md:rounded-br-2xl rounded-br-2xl  md:rounded-bl-none rounded-bl-2xl md:rounded-tr-2xl  overflow-hidden">
          <img
            src="/Images/Simt/Academic.jpeg"
            alt="Shipping Solutions"
            className="w-full h-96"
            onContextMenu={handleContextMenu}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinusCard;
